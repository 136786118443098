import {
  createBaseThemeOptions,
  palettes,
  createUnifiedTheme,
} from '@backstage/theme';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const customLightPrimaryLinkColor = 'rgb(76 148 254)';
const customLightSecondaryLinkColor = 'rgb(89 243 253)';

const customDarkPrimaryLinkColor = 'rgb(76 148 254)';
const customDarkSecondaryLinkColor = 'rgb(89 243 253)';

const getCssBaseline = (primaryColor: string, globalCss?: object) => ({
  '@global': {
    'a, a:visited': {
      textDecoration: 'none',
    },
    'a:not([class]), a:visited:not([class])': {
      color: primaryColor,
    },
    'a:hover:not([class])': {
      textDecoration: 'underline',
    },
    // .aui-root class is used/defined by the API definition plugin.
    // These overrides are needed because links are rendered with a different color
    // than the rest of the application which resulted in links not visible in dark mode.
    '.aui-root .prose a': {
      color: primaryColor,
      'text-decoration': 'none',
    },
    '.aui-root .prose a:hover': {
      textDecoration: 'underline',
    },
    '.aui-root *': {
      'word-wrap': 'break-word',
    },
    ...globalCss,
  },
});

export const getCustomDarkThemeComponents = (
  _: PaletteOptions,
  globalCss: object,
) => ({
  BackstageHeader: {
    styleOverrides: {
      header: {
        backgroundImage: 'unset',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: getCssBaseline(customDarkPrimaryLinkColor, globalCss),
  },
});

const getCustomPalette = (
  palette: PaletteOptions,
  primaryColor: string,
  secondaryColor: string,
) => ({
  primary: {
    ...palette.primary,
    main: primaryColor,
    contrastText: '#fff',
    light: 'rgb(75, 118, 168)',
    dark: '#172B4D',
  },
  secondary: {
    ...palette.secondary,
    contrastText: '#fff',
    main: secondaryColor,
    light: '#ba68c8',
  },
  info: {
    main: '#0065FF',
    light: '#4C9AFF',
    dark: '#0747A6',
  },

  navigation: {
    ...palette.navigation,
    background: '#1c1e1e',
    color: 'rgb(231 230 229)',
    selectedColor: '#fff',
    indicator: '#fff',
  },
});

export const customLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      ...getCustomPalette(
        palettes.light,
        customLightPrimaryLinkColor,
        customLightSecondaryLinkColor,
      ),
    },
  }),
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundImage: 'unset',
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        title: {
          color: palettes.light.textContrast,
        },
        subtitle: {
          color: palettes.light.textContrast,
        },
        type: {
          color: palettes.light.textContrast,
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          color: palettes.light.textContrast,
        },
        value: {
          color: palettes.light.textContrast,
        },
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        selected: {
          color: palettes.light.textContrast,
        },
        defaultTab: {
          color: palettes.light.textContrast,
        },
      },
    },
    PluginCatalogEntityContextMenu: {
      styleOverrides: {
        button: {
          color: palettes.light.primary.main,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: getCssBaseline(customLightPrimaryLinkColor),
    },
  },
});

export const customDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      ...getCustomPalette(
        palettes.dark,
        customDarkPrimaryLinkColor,
        customDarkSecondaryLinkColor,
      ),
      background: {
        default: '#191a1a',
        paper: '#1c1e1e',
      },
    },
  }),
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundImage: 'unset',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: getCssBaseline(customDarkPrimaryLinkColor, {
        // Override swagger-ui svgs to be white since
        // they are black in dark mode by default and
        // are not visible.
        '.swagger-ui svg': {
          fill: '#fff',
        },
        '.swagger-ui .model-toggle:after': {
          filter: 'invert(1)',
        },
      }),
    },
  },
});
