import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
    backgroundColor: '#fff',
  },
  path: {
    fill: '#1c1e1e',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="384.000000pt"
      height="384.000000pt"
      viewBox="0 0 384.000000 384.000000"
      preserveAspectRatio="xMidYMid meet"
      className={classes.svg}
    >
      <g transform="translate(0.000000,384.000000) scale(0.100000,-0.100000)">
        <path
          className={classes.path}
          d="M0 1920 l0 -1920 1920 0 1920 0 0 1920 0 1920 -1920 0 -1920 0 0 -1920z m1985 1188 c3 -13 46 -225 95 -472 49 -247 91 -450 92 -452 2 -1 210 -43 463 -94 253 -50 467 -93 475 -96 12 -4 9 -11 -13 -30 l-28 -24 -567 2 -567 3 -3 568 c-2 529 -1 570 15 592 22 31 31 31 38 3z m-75 -1792 c0 -552 -1 -575 -19 -597 -16 -19 -21 -21 -25 -9 -2 8 -46 222 -96 475 -50 253 -95 462 -100 465 -4 3 -220 48 -479 100 l-470 95 21 22 21 23 574 0 573 0 0 -574z"
        />
      </g>
    </svg>
  );
};

export default LogoIcon;
