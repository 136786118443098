import React, { FC, useEffect } from 'react';
import { MermaidControlsProps } from './types';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import { panAndZoom } from './pan-and-zoom';
import { addControlPanelStyles } from './mermaid-control-styles';
import { BackstageTheme } from '@backstage/theme';
import { useTheme } from '@material-ui/core';

const addMermaidControls = (el: HTMLDivElement, theme: string): boolean => {
  // MermaidControls might already be added
  const mermaidBlocks = el.parentNode?.querySelectorAll(
    '.mermaid:not(.mermaid-controlled)',
  );
  mermaidBlocks?.forEach(mermaidBlock => {
    mermaidBlock.classList.add('mermaid-controlled');

    // Add Mermaid wrapper around panZoomWrapper
    const mermaidWrapper = document.createElement('div', {});
    mermaidBlock.replaceWith(mermaidWrapper);
    mermaidWrapper.appendChild(mermaidBlock);
    mermaidWrapper.style.overflow = 'hidden';
    mermaidWrapper.style.minHeight = '280px';
    mermaidWrapper.style.position = 'relative';

    const panZoomPanel = panAndZoom(mermaidWrapper);
    mermaidWrapper.appendChild(panZoomPanel);

    addControlPanelStyles(mermaidWrapper, theme);
  });

  return mermaidBlocks && mermaidBlocks.length > 0 ? true : false;
};

export const MermaidControlsAddon: FC<MermaidControlsProps> = (
  properties: MermaidControlsProps,
) => {
  const highlightTables = useShadowRootElements<HTMLDivElement>([
    '.highlighttable',
  ]);
  const highlightDivs = useShadowRootElements<HTMLDivElement>(['.highlight']);
  const mermaidBlocks = useShadowRootElements<HTMLDivElement>(['.mermaid']);
  const theme = useTheme<BackstageTheme>();

  useEffect(() => {
    if (highlightTables.length > 0) {
      highlightTables.forEach(highlightTable => {
        addMermaidControls(highlightTable, theme.palette.type);
      });
    }
  }, [highlightTables, theme.palette.type]);

  useEffect(() => {
    if (highlightDivs.length > 0) {
      highlightDivs.forEach(highlightDiv => {
        addMermaidControls(highlightDiv, theme.palette.type);
      });
    }
  }, [highlightDivs, theme.palette.type]);

  useEffect(() => {
    if (mermaidBlocks.length > 0) {
      mermaidBlocks.forEach(mermaidBlock => {
        addMermaidControls(mermaidBlock, theme.palette.type);
      });
    }
  }, [mermaidBlocks, theme.palette.type]);

  return <>{properties.children}</>;
};
