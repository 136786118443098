import React from 'react';
import { TableColumn } from '@backstage/core-components';
import { CatalogTableRow } from '@backstage/plugin-catalog';
import { EntityWithHealth } from './types/entityWithHealth';
import { SdlcRating } from './sdlc/SdlcRating';

const SdlcComponent = ({ entity }: { entity: EntityWithHealth }) => {
  if (!entity.metadata.health) {
    return <>Missing health metadata</>;
  }

  if (entity.metadata.health.error) {
    return <>{entity.metadata.health.error}</>;
  }

  return (
    <a href={`/catalog/default/component/${entity.metadata.name}/sdlc`}>
      <SdlcRating entity={entity} />
    </a>
  );
};

export function createSdlcColumn(options?: {
  title?: string;
  defaultValue?: string;
}): TableColumn<CatalogTableRow> {
  return {
    title: options?.title || 'SDLC Health',
    customSort({ entity: entity1 }, { entity: entity2 }) {
      const health1 =
        (entity1 as EntityWithHealth).metadata?.health?.summary?.passed || 0;
      const health2 =
        (entity2 as EntityWithHealth).metadata?.health?.summary?.passed || 0;

      return health1 - health2;
    },
    render: ({ entity }: { entity: EntityWithHealth }) => (
      <SdlcComponent entity={entity} />
    ),
  };
}
