import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from '@material-ui/core';
import Aws from './aws.svg';
import AwsWhite from './aws-white.svg';
import { useTheme } from '@material-ui/core';

type AwsAccounts = {
  global: {
    prod: string;
    staging: string;
    dev: string;
  };
  china: {
    prod: string;
    staging: string;
    dev: string;
  };
};

type GolarConfig = {
  awsAccounts?: AwsAccounts;
};

const useStyles = makeStyles({
  avatar: {
    backgroundColor: 'transparent',
    borderRadius: 0, // Remove border radius to make it square
  },
});

export const AwsAccountsCard = () => {
  const { avatar } = useStyles();
  const entity = useEntity();

  const isDarkTheme = useTheme().palette.type === 'dark';

  let awsAccounts: AwsAccounts | undefined;
  const golarConfig = entity.entity.metadata.golar;
  if (typeof golarConfig === 'object') {
    awsAccounts = (golarConfig as GolarConfig).awsAccounts;
  }

  if (!awsAccounts) {
    return null;
  }

  const renderAccountInfo = (account: string, accountName: string) => (
    <Box display="flex" justifyContent="space-between">
      <strong>
        {accountName.charAt(0).toUpperCase() + accountName.slice(1)}
      </strong>
      <span>{account}</span>
    </Box>
  );

  return (
    <Card>
      <CardHeader
        title="Accounts"
        titleTypographyProps={{ variant: 'h6' }}
        avatar={
          <Avatar className={avatar}>
            <img src={isDarkTheme ? AwsWhite : Aws} alt="" />
          </Avatar>
        }
      />
      <Divider />
      <CardContent>
        {awsAccounts.global && (
          <Box mb={2}>
            <Box mb={1}>
              <strong>Global</strong>
            </Box>
            <Divider />
            {['prod', 'staging', 'dev'].map(
              account =>
                (awsAccounts!.global as Record<string, string>)[account] &&
                renderAccountInfo(
                  (awsAccounts!.global as Record<string, string>)[account],
                  account,
                ),
            )}
          </Box>
        )}
        {awsAccounts.china && (
          <Box>
            <Box mb={1}>
              <strong>China</strong>
            </Box>
            <Divider />
            {['prod', 'staging', 'dev'].map(
              account =>
                (awsAccounts!.china as Record<string, string>)[account] &&
                renderAccountInfo(
                  (awsAccounts!.china as Record<string, string>)[account],
                  account,
                ),
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
