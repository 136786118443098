import React from 'react';
import { CheckHealth } from '../../types/entityWithHealth';
import { CheckHealthHeader } from './CheckHealthHeader';

export interface CheckProps {
  checkHealth: CheckHealth;
}

export const CodeownersContent: React.FC<CheckProps> = ({ checkHealth }) => {
  return (
    <>
      <CheckHealthHeader checkHealth={checkHealth} name="Codeowners Check" />

      <p>
        This check ensures that the repository has a <code>CODEOWNERS</code>{' '}
        file.
      </p>
      <h2>What is a CODEOWNERS file?</h2>
      <p>
        A <code>CODEOWNERS</code> file is a file that defines the owners of a
        repository. It is used to define who is responsible for reviewing and
        approving changes to the repository.
      </p>
      <h2>How do I fix it?</h2>
      <p>
        To fix this issue, add a <code>CODEOWNERS</code> file in the root,{' '}
        <code>.github/</code>, or <code>docs/</code> directory of the
        repository.
      </p>
      <p>
        For more information including the supported syntax of the file, check
        the{' '}
        <a href="https://docs.github.com/en/repositories/managing-your-repositorys-settings-and-features/customizing-your-repository/about-code-owners">
          github docs
        </a>
        .
      </p>
    </>
  );
};
