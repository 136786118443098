import React from 'react';
import { CheckHealthHeader } from './CheckHealthHeader';
import { CheckHealth } from '../../types/entityWithHealth';

export interface CheckProps {
  checkHealth: CheckHealth;
}

export const WellArchitectedReviewContent: React.FC<CheckProps> = ({
  checkHealth,
}) => {
  return (
    <>
      <CheckHealthHeader
        checkHealth={checkHealth}
        name="Well Architected Review Check"
      />

      <p>
        This check validates that a Well-Architected review has been completed
        for the repository within the last 6 months.
      </p>

      <h2>Requirements</h2>
      <ul>
        <li>
          An AWS Well-Architected workload created within the{' '}
          <a href="https://docs.aws.amazon.com/wellarchitected/latest/userguide/intro.html">
            AWS Well-Architected Tool
          </a>{' '}
          in any Polestar workload account
        </li>
        <li>
          The workload is tagged with <code>repos: repo-a repo-b ...</code>{' '}
          where <code>repo-a</code>, <code>repo-b</code>, etc. are the names of
          the repositories that are associated with the review
        </li>
        <li>
          The workload has saved a milestone within the last{' '}
          <code>6 months</code>
        </li>
      </ul>

      <h2>AWS Well-Architected tool review lenses</h2>
      <p>
        The workload must include the SDLC Review lens, this can be created
        correctly via the shared{' '}
        <a href="https://eu-north-1.console.aws.amazon.com/wellarchitected/home?region=eu-north-1#/review-templates/arn%3Aaws%3Awellarchitected%3Aeu-north-1%3A587813452421%3Areview-template%2F665f8533b2facc0eb47998c029adb801/overview">
          Review templates
        </a>{' '}
        (log in to your account before clicking the link) in the AWS console.
      </p>

      <h2>SDLC Review</h2>
      <p>
        Details about the SDLC review itself can be found at{' '}
        <a href="https://polestarjira.atlassian.net/wiki/spaces/digitaloffice/pages/3758952145/SDLC+Review+2.0">
          SDLC Review 2.0
        </a>
        .
      </p>

      <h2>Scope</h2>
      <p>This check collects data from all AWS accounts</p>
    </>
  );
};
