import React from 'react';
import { Page } from '@backstage/core-components';
import {
  TechDocsReaderPage,
  TechDocsReaderPageContent,
} from '@backstage/plugin-techdocs';
import styled from 'styled-components';

interface Props {
  kind: string;
  name: string;
  namespace: string;
  widget?: boolean;
}

const Container = styled.div`
  overflow-y: auto;
  height: auto;

  main {
    height: auto;
  }
`;

export const PolestarDocs: React.FC<Props> = ({
  kind,
  name,
  namespace,
  widget,
}) => {
  if (widget) {
    return (
      <Container>
        <TechDocsReaderPage entityRef={{ kind, name, namespace }}>
          <Page themeId="documentation">
            <TechDocsReaderPageContent />
          </Page>
        </TechDocsReaderPage>
      </Container>
    );
  }

  return (
    <TechDocsReaderPage entityRef={{ kind, name, namespace }}>
      <Page themeId="documentation">
        <TechDocsReaderPageContent />
      </Page>
    </TechDocsReaderPage>
  );
};
