import React from 'react';
import { EntityWithHealth } from '../types/entityWithHealth';
import StarIcon from '@material-ui/icons/Star';
import Rating from '@mui/material/Rating';
import { useTheme } from '@material-ui/core';

export const SdlcRating = ({ entity }: { entity: EntityWithHealth }) => {
  const passed = entity.metadata.health?.summary?.passed || 0;
  const total = entity.metadata.health?.summary?.total || 5;
  const percentage = passed / total;
  const theme = useTheme();

  const rating = percentage * 5;
  return (
    <Rating
      name="sdlc-health-rating"
      value={rating}
      precision={0.5}
      max={5}
      size="small"
      emptyIcon={
        <StarIcon
          style={{ opacity: 0.55, color: theme.palette.text.primary }}
          fontSize="inherit"
        />
      }
      readOnly
    />
  );
};
