import React from 'react';
import { CodeSnippet } from '@backstage/core-components';
import { CheckHealth } from '../../types/entityWithHealth';
import { CheckHealthHeader } from './CheckHealthHeader';

export interface CheckProps {
  checkHealth: CheckHealth;
}

export const ServiceMetadataContent: React.FC<CheckProps> = ({
  checkHealth,
}) => {
  enum GolarType {
    api = 'api',
    backend = 'backend',
    frontend = 'frontend',
    infrastructure = 'infrastructure',
    library = 'library',
    'mobile-app' = 'mobile-app',
    'end-to-end-tests' = 'end-to-end-tests',
  }

  const exampleServiceMetadata = `  apiVersion: backstage.io/v1alpha1
  kind: Component
  metadata:
    name: <name of project>
    description: <short description of the project>
    annotations:
      github.com/project-slug: polestar/<repo>
      backstage.io/techdocs-ref: dir:.
    links:
      - url: https://<url>
      (..optional)
    golar:
      type: <one of: ${Object.values(GolarType)}>
      // You must define either prod in global or china, rest is optional
      // For products not deployed to AWS, define the account where you Well-architected review is done
      // NOTE: Account ID's are provided as a string value, e.g. "123456789012"
      awsAccounts:
        global:
          prod: "<aws account id>"
          staging: "<aws account id>"
          dev: "<aws account id>"
        china:
          prod: "<aws account id>"
          staging: "<aws account id>"
          dev: "<aws account id>"
  spec:
    type: <type>
    lifecycle: production
    owner: <team>
  ---
  apiVersion: backstage.io/v1alpha1
  kind: API
  metadata:
    name: <api name>
    description: <api description>
  spec:
    type: openapi
    lifecycle: production
    owner: <team>
    definition: <definition>`;

  return (
    <>
      <CheckHealthHeader
        checkHealth={checkHealth}
        name="Application Manifest Check"
      />

      <p>
        This check ensures that the repository has a{' '}
        <code>catalog-info.yaml</code> file.
      </p>

      <h2>What is an application manifest file?</h2>

      <p>
        An <code>Application Manifest</code> file is a YAML file that conforms
        to the{' '}
        <a href="https://backstage.io/docs/features/software-catalog/descriptor-format">
          descriptor format of catalog entities
        </a>{' '}
        used in Backstage. This file describes the details of the software in
        your repository, such as its name, description, type, and owner. At
        Polestar, we may use some specific sections in the{' '}
        <code>catalog-info.yaml</code> file.
      </p>

      <h2>How is it used?</h2>

      <p>
        Backstage is a developer portal that provides software cataloging
        feature. The <code>catalog-info.yaml</code> file is intended to support
        Backstage's software cataloging. It allows teams to browse and discover
        software assets, understand the purpose and scope of each asset, and
        collaborate with other teams.
      </p>

      <h2>How do I fix it?</h2>

      <p>
        To fix this issue, add a <code>catalog-info.yaml</code> file in the root
        directory of the repository. You could follow the Backstage descriptor
        syntax, but note that:
      </p>

      <ul>
        <li>
          Each repository should contain only one Backstage entity of{' '}
          <code>kind=Component</code>
        </li>
        <li>
          Notice that you can still have other entities kinds like API to
          describe an API
        </li>
        <li>
          Only one instance of a <code>Component</code> should define a{' '}
          <code>golar</code> metadata section.
        </li>
        <li>
          The <code>golar</code> metadata section should contain a{' '}
          <code>type</code> field with one of the following values:{' '}
          {Object.values(GolarType).join(', ')}
        </li>
        <li>
          The <code>golar</code> metadata section should contain an{' '}
          <code>awsAccounts</code> where either <code>global</code> or{' '}
          <code>china</code> contains a <code>prod</code> field with a valid AWS
          account ID. If your product is not deployed to AWS, you can define the
          account where your Well-architected review is done.
        </li>
      </ul>

      <h2>Example</h2>

      <pre>
        <CodeSnippet language="yaml" text={exampleServiceMetadata} />
      </pre>
    </>
  );
};
