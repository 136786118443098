import { createPlugin } from '@backstage/core-plugin-api';
import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';

import { MermaidControlsAddon } from './mermaid-controls';
import type { MermaidControlsProps } from './mermaid-controls';

export const mermaidControlsPlugin = createPlugin({
  id: 'mermaid-controls',
});

export const MermaidControls = mermaidControlsPlugin.provide(
  createTechDocsAddonExtension<MermaidControlsProps>({
    name: 'MermaidControls',
    location: TechDocsAddonLocations.Content,
    component: MermaidControlsAddon,
  }),
);
