import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@internal/plugin-tech-radar';

export class TechRadarClient implements TechRadarApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async load(): Promise<TechRadarLoaderResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('tech-radar');
    const apiResponse = await this.fetchApi.fetch(`${baseUrl}/data`);

    if (!apiResponse.ok) {
      throw new Error('Failed fetching techradar data');
    }

    const radarJson = (await apiResponse.json()) as TechRadarLoaderResponse;

    return {
      ...radarJson,
      entries: radarJson.entries.map(entry => ({
        ...entry,
        timeline: entry.timeline.map(timeline => ({
          ...timeline,
          date: new Date(timeline.date),
        })),
      })),
    };
  }
}
