import React from 'react';
import { CheckHealth } from '../../types/entityWithHealth';

export const CheckHealthHeader: React.FC<{
  name: string;
  checkHealth: CheckHealth;
}> = ({ name, checkHealth }) => {
  let icon = '⚠️';
  if (checkHealth) {
    icon = checkHealth.valid ? '✅' : '❌';
  }
  return (
    <>
      <h1>
        {icon} {name}
      </h1>
      {!checkHealth && <b>No check data available for this component.</b>}
    </>
  );
};
