import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CheckHealth } from '../../types/entityWithHealth';
import { CodeSnippet } from '@backstage/core-components';
import { CheckHealthHeader } from './CheckHealthHeader';

export interface CheckProps {
  checkHealth: CheckHealth;
}

type LicenseMetadata = {
  count: number;
  packages: {
    name: string;
    version: string;
    license: string;
    packageType: string;
  }[];
};

const APPROVED_LICENSES = [
  '0BSD',
  'Apache-1.0',
  'Apache-2.0',
  'BSD-2-Clause',
  'BSD-3-Clause',
  'CC0-1.0',
  'ISC',
  'MIT-0',
  'MIT',
  'MPL-1.0',
  'MPL-1.1',
  'MPL-2.0',
  'Unlicense',
];

const exampleLicenseConfig = `{
  // Skip the check
  "skip": false,
  // Ignore dependencies from the check
  "ignore": ["prettier"],
  // Overwrite the license of a dependency
  "overwrite": {
    "@types/node": "MIT"
  }
}`;

export const LicenseContent: React.FC<CheckProps> = ({ checkHealth }) => {
  const renderNonCompliantPackages = () => {
    const nonCompliantPackages = checkHealth.metadata as LicenseMetadata;
    if (nonCompliantPackages.count === 0) {
      return <></>;
    }
    const hasMoreNonCompliantPackages =
      nonCompliantPackages.count > nonCompliantPackages.packages.length;

    return (
      <>
        <h2>Non-compliant packages</h2>
        <p>
          There are <b>{nonCompliantPackages.count}</b> non-compliant packages.
        </p>
        <p>
          The following packages are not compliant:{' '}
          {hasMoreNonCompliantPackages ? (
            <i>
              (Note that this only shows the first{' '}
              {nonCompliantPackages.packages.length} packages)
            </i>
          ) : (
            <></>
          )}
        </p>

        <TableContainer component={Paper}>
          <Table size="small" aria-label="a table with non-compliant packages">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Package</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>License</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonCompliantPackages.packages.map(pkg => (
                <TableRow>
                  <TableCell>{pkg.packageType}</TableCell>
                  <TableCell>{pkg.name}</TableCell>
                  <TableCell>{pkg.version}</TableCell>
                  <TableCell>{pkg.license}</TableCell>
                </TableRow>
              ))}
              {hasMoreNonCompliantPackages ? (
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <b>
                      +{' '}
                      {nonCompliantPackages.count -
                        nonCompliantPackages.packages.length}{' '}
                      additional packages
                    </b>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <>
      <CheckHealthHeader name="License check" checkHealth={checkHealth} />

      <p>
        This check tries to validate that dependencies are licensed under an
        Polestar approved license (<b>{APPROVED_LICENSES.join(', ')}</b>).
      </p>

      <blockquote>
        <p>
          This is a <em>best-effort check</em> and will not be able to determine
          the license of all dependencies. Currently, the check will focuses on
          NPM and Nuget packages. For NPM it will only check transitive
          dependencies if a lockfile is checked in. For NuGet it will{' '}
          <em>not</em> check transitive dependencies.
        </p>
      </blockquote>

      {checkHealth && renderNonCompliantPackages()}

      <h2>Approved Licenses</h2>
      <ul>
        {APPROVED_LICENSES.map(license => (
          <li>{license}</li>
        ))}
      </ul>

      <h2>Configuration</h2>

      <p>
        The check can be configured using a <code>.golar/license.json</code>{' '}
        file in the repository. The following options are available:
      </p>

      <CodeSnippet language="json" text={exampleLicenseConfig} />

      <h2>Adding a dependency to the check</h2>

      <p>
        You can explore your repository's{' '}
        <a href="https://docs.github.com/en/code-security/supply-chain-security/understanding-your-software-supply-chain/exploring-the-dependencies-of-a-repository">
          dependency graph
        </a>{' '}
        to check which dependencies Golar will try to get and validate licences
        for.
      </p>

      <p>
        If a dependency is not listed, for example transitive NuGet
        dependencies, they can be added using the{' '}
        <a href="https://docs.github.com/en/code-security/supply-chain-security/understanding-your-software-supply-chain/using-the-dependency-submission-api">
          Dependency submission API
        </a>
        .
      </p>

      <p>
        To determine a dependency license, the check will use the following
        sources:
      </p>

      <ul>
        <li>Github's SBOM (Software Bill of Materials) export API</li>
        <li>NPM's registry API</li>
        <li>Nuget's registry API</li>
      </ul>

      <p>
        For more information{' '}
        <a href="https://polestarjira.atlassian.net/wiki/spaces/digitaloffice/pages/3071803798/Open+Source+Guidelines+1.0">
          Polestar's Open Source Guidelines
        </a>
        .
      </p>
    </>
  );
};
