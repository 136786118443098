import React from 'react';
import {
  CustomHomepageGrid,
  HomePageCompanyLogo,
  HomePageRandomJoke,
  HomePageStarredEntities,
  homePlugin,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { createComponentExtension } from '@backstage/core-plugin-api';

const PolestarDocsWidget = homePlugin.provide(
  createComponentExtension({
    name: 'PolestarDocs',
    component: {
      lazy: () =>
        import('../polestarDocs/PolestarDocs').then(f => f.PolestarDocs),
    },
  }),
);
export const HomePage = () => {
  const polestarDocsEntity = {
    name: 'polestar-docs',
    kind: 'component',
    namespace: 'default',
    widget: true,
  };

  return (
    <div style={{ padding: '5px' }}>
      <CustomHomepageGrid
        config={[
          {
            component: 'PolestarDocs',
            x: 0,
            y: 0,
            width: 12,
            height: 12,
          },
        ]}
      >
        <PolestarDocsWidget {...polestarDocsEntity} />
        <HomePageCompanyLogo />
        <HomePageSearchBar />
        <HomePageRandomJoke />
        <HomePageStarredEntities />
      </CustomHomepageGrid>
    </div>
  );
};
