import octicons from '@primer/octicons';

export const panAndZoom = (el: HTMLElement) => {
  const ZOOM_MIN = 0.5;
  const ZOOM_MAX = 8;

  let zoomLevel = 1;
  const translate = { x: 0, y: 0 };

  const transformSvg = (zoom: number, x: number, y: number) => {
    const svg = el.getElementsByTagName('svg')[0];
    svg.style.transform = `translate(${x}px, ${y}px) scale(${zoom})`;
  };

  const reset = () => {
    zoomLevel = 1;
    translate.x = 0;
    translate.y = 0;
    transformSvg(zoomLevel, translate.x, translate.y);
  };

  const doMove = (vertical: number, horizonal: number) => {
    translate.y += vertical;
    translate.x += horizonal;
    transformSvg(zoomLevel, translate.x, translate.y);
  };

  const doZoom = (value: number) => {
    zoomLevel += value;
    zoomLevel = Math.min(Math.max(ZOOM_MIN, zoomLevel), ZOOM_MAX);
    transformSvg(zoomLevel, translate.x, translate.y);
  };

  const createElement = (
    tag: string,
    className: string,
    innerHTML: string,
    onClick: ((event: MouseEvent) => void) | null,
  ): HTMLElement => {
    const element = document.createElement(tag);
    element.className = className;
    element.innerHTML = innerHTML;
    element.onclick = onClick;
    return element;
  };

  const controlPanel = createElement(
    'div',
    'mermaid-viewer-control-panel',
    '',
    null,
  );
  const df = document.createDocumentFragment();
  df.appendChild(
    createElement('button', 'btn zoom-in', octicons.plus.toSVG(), () =>
      doZoom(0.1),
    ),
  );
  df.appendChild(
    createElement('button', 'btn zoom-out', octicons.dash.toSVG(), () =>
      doZoom(-0.1),
    ),
  );
  df.appendChild(
    createElement('button', 'btn reset', octicons.sync.toSVG(), reset),
  );
  df.appendChild(
    createElement('button', 'btn up', octicons['chevron-up'].toSVG(), () =>
      doMove(100, 0),
    ),
  );
  df.appendChild(
    createElement('button', 'btn down', octicons['chevron-down'].toSVG(), () =>
      doMove(-100, 0),
    ),
  );
  df.appendChild(
    createElement('button', 'btn left', octicons['chevron-left'].toSVG(), () =>
      doMove(0, 100),
    ),
  );
  df.appendChild(
    createElement(
      'button',
      'btn right',
      octicons['chevron-right'].toSVG(),
      () => doMove(0, -100),
    ),
  );

  controlPanel.appendChild(df);

  return controlPanel;
};
