import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { MermaidControls } from '@internal/plugin-mermaid-controls';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';

import {
  AlertDisplay,
  Header,
  IdentityProviders,
  OAuthRequestDialog,
  Page,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  configApiRef,
  discoveryApiRef,
  githubAuthApiRef,
  IdentityApi,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { setTokenCookie } from './cookieAuth';

import CssBaseline from '@material-ui/core/CssBaseline';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { Root } from './components/Root/Root';
import { searchPage } from './components/search/SearchPage';
import { entityPage } from './components/catalog/EntityPage';
import { HomePage } from './components/home/HomePage';
import { PolestarDocs } from './components/polestarDocs/PolestarDocs';
import { createSdlcColumn } from './components/catalog/SdlcColumn';
import { customDarkTheme, customLightTheme } from './customizations/theme';
import { UnifiedThemeProvider } from '@backstage/theme';
import { TechRadarPage } from '@internal/plugin-tech-radar';

const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      const config = useApi(configApiRef);

      const providers: IdentityProviders = [];

      if (config.getString('auth.environment') === 'development') {
        providers.push('guest');
      } else {
        providers.push({
          id: 'azure-ad-auth-provider',
          title: 'Polestar Account',
          message: 'Sign in using Azure AD account',
          apiRef: microsoftAuthApiRef,
        });
      }

      return (
        <SignInPage
          {...props}
          align="center"
          auto
          providers={providers}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );

            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'light-theme',
      title: 'Light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={customLightTheme}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'dark-theme',
      title: 'Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={customDarkTheme}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      ),
    },
  ],
  featureFlags: [
    {
      pluginId: '', // pluginId is required for feature flags in plugins. It can be left blank for a feature flag leveraged in the application.
      name: 'home-page',
      description: 'Enables the customizable home page',
    },
  ],
});

const catalogColumns = [
  CatalogTable.columns.createNameColumn(),
  CatalogTable.columns.createOwnerColumn(),
  CatalogTable.columns.createSpecTypeColumn(),
  createSdlcColumn(),
  CatalogTable.columns.createSpecLifecycleColumn(),
  CatalogTable.columns.createMetadataDescriptionColumn(),
];

export function RequireGithub(props: {
  children: ReactNode;
}): JSX.Element | null {
  const [isAuthorized, setAuthorized] = useState(false);
  const [authFailed, setAuthFailed] = useState(false);
  const gh = useApi(githubAuthApiRef);

  useEffect(() => {
    async function auth() {
      try {
        await gh.getAccessToken(['repo']);
        setAuthorized(true);
      } catch (e) {
        /* noop, user did not log in */
        setAuthFailed(true);
        return;
      }
    }

    if (!isAuthorized && !authFailed) {
      auth();
    }
  }, [gh, isAuthorized, authFailed]);

  if (authFailed) {
    return (
      <Page themeId="home">
        <Header title="You need to be authenticated with Github to use Templates" />
      </Page>
    );
  }

  return <>{props.children}</>;
}

const routes = (
  <FlatRoutes>
    <FeatureFlagged with="home-page">
      <Route path="/" element={<HomepageCompositionRoot />}>
        <HomePage />
      </Route>
    </FeatureFlagged>
    <FeatureFlagged without="home-page">
      <Route path="/" element={<Navigate to="catalog" />} />
    </FeatureFlagged>
    <Route
      path="/getting-started"
      element={
        <PolestarDocs kind="component" name="backstage" namespace="default" />
      }
    />
    <Route
      path="/catalog"
      element={<CatalogIndexPage columns={catalogColumns} />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <MermaidControls>
          <Mermaid
            darkConfig={{ theme: 'dark' }}
            lightConfig={{ theme: 'forest' }}
          />
        </MermaidControls>
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <RequireGithub>
          <ScaffolderPage />
        </RequireGithub>
      }
    />
    <Route
      path="/api-docs"
      element={<ApiExplorerPage columns={catalogColumns} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route
      path="/tech-radar"
      element={
        <TechRadarPage
          title="Tech Radar"
          subtitle="Hi! The visualized data in this page based on @polestar/tech-radar repository. Head there for more information about how to contribute!"
          pageTitle=""
          width={1500}
          height={800}
        />
      }
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
