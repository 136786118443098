import React from 'react';
import { CheckHealth } from '../../types/entityWithHealth';
import { CheckHealthHeader } from './CheckHealthHeader';

export interface CheckProps {
  checkHealth: CheckHealth;
}

export const RepositoryNameContent: React.FC<CheckProps> = ({
  checkHealth,
}) => {
  const blacklistedNames = (): string => {
    let list = '';
    for (const name of ['git', 'devops']) {
      list += `- \`${name}\`\n`;
    }

    return list;
  };

  return (
    <>
      <CheckHealthHeader
        checkHealth={checkHealth}
        name="Repository Name Check"
      />

      <p>
        This check ensures that the given repository name follows the Polestar
        git repository naming conventions.
      </p>

      <h2>Repository naming convention</h2>

      <p>
        The naming convention for git repositories at Polestar is to either use
      </p>

      <h3>Kebab-case</h3>

      <p>
        In kebab-case, repository names should be written in lowercase
        alphanumeric letters with words separated by hyphens (<code>-</code>).
        For example, <code>my-awesome-repo</code> or{' '}
        <code>project-template</code>.
      </p>

      <h3>Dot notation</h3>

      <p>
        Alternatively, repository names can be written in lowercase alphanumeric
        letters with words separated by dots (<code>.</code>). For example,{' '}
        <code>my.awesome.repo</code> or <code>project.template</code>.
      </p>

      <h3>Blacklisted words</h3>

      <p>
        Git repository names are not allowed to <em>contain</em> any of the
        following words: ${blacklistedNames()}
      </p>

      <h2>More information</h2>

      <p>
        For more information, visit the{' '}
        <a href="https://polestarjira.atlassian.net/wiki/spaces/DEV/pages/3195601020/GitHub+Repositories#Follow-Conventions">
          GitHub repositories
        </a>{' '}
        page on Confluence.
      </p>
    </>
  );
};
