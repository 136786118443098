import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { EmptyState } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { CheckHealth, EntityWithHealth } from '../types/entityWithHealth';
import { RepositoryNameContent } from './checks/RepositoryNameContent';
import { CodeownersContent } from './checks/CodeownersContent';
import { DependabotContent } from './checks/DependabotContent';
import { ServiceMetadataContent } from './checks/ServiceMetadataContent';
import { LicenseContent } from './checks/LicenseContent';
import { WellArchitectedReviewContent } from './checks/WellArchitectedReviewContent';
import { Route, Routes, useParams } from 'react-router';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { SdlcRating } from './SdlcRating';

const CheckDescription: React.FC<{
  checks: { [key: string]: CheckHealth };
}> = ({ checks }) => {
  const { check } = useParams();
  if (!check) {
    return <>Click on a check to view details</>;
  }

  const checkHealth = checks[check];

  switch (check) {
    case 'codeowners':
      return <CodeownersContent checkHealth={checkHealth} />;
    case 'dependabot_file':
      return <DependabotContent checkHealth={checkHealth} />;
    case 'service_metadata':
      return <ServiceMetadataContent checkHealth={checkHealth} />;
    case 'repository_name':
      return <RepositoryNameContent checkHealth={checkHealth} />;
    case 'license':
      return <LicenseContent checkHealth={checkHealth} />;
    case 'well_architected_review':
      return <WellArchitectedReviewContent checkHealth={checkHealth} />;
    default:
      return <>Click on a check to view details</>;
  }
};

const AboutContent = () => {
  return (
    <>
      <h1>About</h1>
      <h3>What's SDLC health?</h3>
      <p>
        The "SDLC health" is a measurement that is designed to help you to
        understand how well your component is set up for the software
        development lifecycle (
        <Link href="https://polestarjira.atlassian.net/wiki/spaces/digitaloffice/pages/3095921011/Software+Development+Lifecycle+SDLC">
          SDLC
        </Link>
        ) definition at Polestar.
      </p>
      <p>
        The health score is based on how many of the{' '}
        <Link href="https://polestarjira.atlassian.net/wiki/spaces/digitaloffice/pages/3756228811/Software+Development+at+Polestar#Software-Baseline">
          Software Baseline
        </Link>{' '}
        checks that are fulfilled from the list of{' '}
        <Link href="https://polestarjira.atlassian.net/wiki/spaces/digitaloffice/pages/3756228811/Software+Development+at+Polestar#The-most-significant-SDLC-activities">
          the most significant SDLC activities
        </Link>
        .
      </p>
    </>
  );
};

export const SdlcHealthContent = () => {
  const catalogEntity = useEntity();
  const entity = catalogEntity.entity as EntityWithHealth;

  const checks = entity.metadata.health?.checks ?? {};
  const checkNames = Object.keys(checks);

  const theme = useTheme();

  const { pathname } = useLocation();
  const currentPath = pathname.split('/').pop() ?? '';

  const [selectedMenuItem, setSelectedMenuItem] = useState(
    checkNames.indexOf(currentPath) > -1 ? currentPath : 'about',
  );

  const useStyles = makeStyles({
    menuItem: {
      '&.Mui-disabled': {
        opacity: 1,
      },
    },
  });

  const classes = useStyles();

  if (!entity.metadata.health || entity.metadata.health.error) {
    return (
      <EmptyState
        title="Not available for this entity"
        missing="info"
        description="It seems that SDLC health status is not available for this entity."
      />
    );
  }

  const prettify = (str: string) =>
    `${str.charAt(0).toUpperCase()}${str.slice(1)}`.split('_').join(' ');

  const getIcon = (checkHealth: CheckHealth) => {
    if (!checkHealth) {
      return <ReportProblemOutlinedIcon style={{ color: '#ffcd38' }} />;
    }
    return checkHealth.valid ? (
      <CheckIcon style={{ color: '#4caf50' }} />
    ) : (
      <CloseIcon style={{ color: '#f6685e' }} />
    );
  };

  const RedirectToAbout = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
      navigate('./');
    }, [navigate]);

    return null;
  };

  return (
    <Grid container spacing={3}>
      <Grid item lg={3} md={4} xs={12}>
        <MenuList dense>
          <MenuItem className={classes.menuItem} disabled>
            <ListItemText>Health score</ListItemText>
            <SdlcRating entity={entity} />
          </MenuItem>
          <Divider />

          <NavLink to="./">
            <MenuItem
              selected={selectedMenuItem === 'about'}
              onClick={() => setSelectedMenuItem('about')}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText style={{ color: theme.palette.text.primary }}>
                About
              </ListItemText>
            </MenuItem>
          </NavLink>
          <Divider />
          <MenuItem className={classes.menuItem} disabled>
            <ListItemText>Checks</ListItemText>
          </MenuItem>
          {checkNames.map(key => {
            return (
              <>
                <NavLink to={`./checks/${key}`}>
                  <MenuItem
                    key={key}
                    selected={selectedMenuItem === key}
                    onClick={() => setSelectedMenuItem(key)}
                  >
                    <ListItemIcon style={{ minWidth: '35px' }}>
                      {getIcon(checks[key])}
                    </ListItemIcon>
                    <ListItemText style={{ color: theme.palette.text.primary }}>
                      {prettify(key)}
                    </ListItemText>
                  </MenuItem>
                </NavLink>
              </>
            );
          })}
        </MenuList>
      </Grid>
      <Grid item lg={9} md={8} xs={12}>
        <Card>
          <CardContent>
            <Routes>
              <Route
                path="/checks/:check"
                element={<CheckDescription checks={checks} />}
              />
              <Route path="/" element={<AboutContent />} />
              <Route path="*" element={<RedirectToAbout />} />
            </Routes>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
