import { ApiEntity } from '@backstage/catalog-model';
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  githubAuthApiRef,
} from '@backstage/core-plugin-api';
import {
  apiDocsConfigRef,
  defaultDefinitionWidgets,
  GraphQlDefinitionWidget,
} from '@backstage/plugin-api-docs';
import React from 'react';
import { TechRadarClient } from './lib/TechRadarClient';
import { techRadarApiRef } from '@internal/plugin-tech-radar';

const apiDocsFactory = createApiFactory({
  api: apiDocsConfigRef,
  deps: {},
  factory: () => {
    // load the default widgets
    const definitionWidgets = defaultDefinitionWidgets();

    return {
      getApiDefinitionWidget: (apiEntity: ApiEntity) => {
        if (apiEntity.spec.type === 'graphql') {
          return {
            type: 'graphql',
            title: 'GraphQL',
            rawLanguage: 'graphql',
            component: (definition: string) => {
              // Remove appsync directives
              const appsyncDirectives = [
                '@aws_api_key',
                '@aws_iam',
                '@aws_oidc',
                '@aws_cognito_user_pools',
                '@aws_auth',
                '@aws_subscribe',
              ];

              let escaped = definition;
              if (!definition.includes('directive @aws')) {
                for (const directive of appsyncDirectives) {
                  escaped = escaped.replaceAll(directive, '');
                }
              }

              return <GraphQlDefinitionWidget definition={escaped} />;
            },
          };
        }

        // Return default widget for other types
        return definitionWidgets.find(d => d.type === apiEntity.spec.type);
      },
    };
  },
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  apiDocsFactory,
  createApiFactory({
    api: scmAuthApiRef,
    deps: { githubAuthApi: githubAuthApiRef },
    factory: ({ githubAuthApi }) =>
      ScmAuth.merge(ScmAuth.forGithub(githubAuthApi)),
  }),
  createApiFactory({
    api: techRadarApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ discoveryApi, fetchApi }) => {
      return new TechRadarClient({ discoveryApi, fetchApi });
    },
  }),
];
