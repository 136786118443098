export const addControlPanelStyles = (
  el: HTMLElement | null | undefined,
  theme: string,
) => {
  if (!el) return;

  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = getStyles(theme);
  el.appendChild(style);
};
function getStyles(theme: string) {
  return `
  .btn {
    position: relative;
    display: inline-block;
    padding: 5px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid;
    border-radius: 6px;
    appearance: none
  }
  
  .btn {
    color: ${theme === 'dark' ? '#21262d' : '#24292f'};
    background-color: ${theme === 'dark' ? '#21262d' : '#f6f8fa'};
    border-color:  ${
      theme === 'dark' ? 'rgba(240, 246, 252, 0.1)' : 'rgba(27, 31, 36, 0.15)'
    };
    box-shadow: 0 0 transparent, 0 0 transparent;
    transition: .2s cubic-bezier(0.3, 0, 0.5, 1);
    transition-property: color, background-color, border-color
  }
  
  .btn:hover,
  .btn.hover,
  [open]>.btn {
    background-color: ${theme === 'dark' ? '#30363d' : '#f3f4f6'};
    border-color: ${theme === 'dark' ? '#8b949e' : 'rgba(27, 31, 36, 0.15)'};
    transition-duration: .1s
  }

  .btn:hover {
    text-decoration: none
  }

  .btn:disabled,
  .btn.disabled,
  .btn[aria-disabled=true] {
    cursor: default
  }

  .btn:disabled .octicon,
  .btn.disabled .octicon,
  .btn[aria-disabled=true] .octicon {
    color: var(--color-fg-muted)
  }

  .btn i {
    font-style: normal;
    font-weight: 500;
    opacity: .75
  }

  .btn .octicon {
    margin-right: 4px;
    color: var(--color-fg-muted);
    vertical-align: text-bottom
  }

  .btn .octicon:only-child {
    margin-right: 0
  }

  .mermaid-controlled {
    position: relative;
  }
  
  .mermaid-viewer-control-panel {
    position: absolute;
    bottom: 1em;
    right: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: .2em;
    background-color: none;
  }

  .mermaid-viewer-control-panel>button {
    padding: 5px 7px;
    ${theme === 'dark' ? 'fill:#fff;' : '#000'} 
  }

  .mermaid-viewer-control-panel>.zoom-in {
    grid-column: 3;
    grid-row: 1
  }

  .mermaid-viewer-control-panel>.zoom-out {
    grid-column: 3;
    grid-row: 3
  }

  .mermaid-viewer-control-panel>.reset {
    grid-column: 2;
    grid-row: 2
  }

  .mermaid-viewer-control-panel>.up {
    grid-column: 2;
    grid-row: 1
  }

  .mermaid-viewer-control-panel>.down {
    grid-column: 2;
    grid-row: 3
  }

  .mermaid-viewer-control-panel>.left {
    grid-column: 1;
    grid-row: 2
  }

  .mermaid-viewer-control-panel>.right {
    grid-column: 3;
    grid-row: 2
  }
  `;
}
