import React from 'react';
import { CheckHealthHeader } from './CheckHealthHeader';
import { CheckHealth } from '../../types/entityWithHealth';

export interface CheckProps {
  checkHealth: CheckHealth;
}

export const DependabotContent: React.FC<CheckProps> = ({ checkHealth }) => {
  return (
    <>
      <CheckHealthHeader checkHealth={checkHealth} name="Dependabot Check" />

      <p>
        This check ensures that the repository has a Dependabot configuration
        file.
      </p>

      <h2>What is a Dependabot configuration file?</h2>

      <p>
        A Dependabot file configures how Dependabot should update your
        dependencies. The file should be placed in the <code>.github</code>{' '}
        directory of your repository and be named either{' '}
        <code>dependabot.yml</code> or <code>dependabot.yaml</code>.
      </p>

      <h2>How do I fix it?</h2>

      <p>
        Add a <code>.github/dependabot.yml</code> or a{' '}
        <code>.github/dependabot.yaml</code> file to your repository.
      </p>

      <p>
        Refer to{' '}
        <a href="https://docs.github.com/en/code-security/dependabot/dependabot-version-updates/configuration-options-for-the-dependabot.yml-file">
          GitHub's official documentation
        </a>{' '}
        for more information on the syntax of the configuration file.
      </p>

      <p>
        Note that you must give Dependabot access to GitHub Packages if you are
        using any internal polestar packages.
      </p>

      <p>
        For reference, refer to the Dependabot configuration in the boilerplate
        repositories:
      </p>

      <ul>
        <li>
          <a href="https://github.com/polestar/generator-boilerplate/blob/main/generators/generator-git/src/app/templates/skeleton/.github/dependabot.yml#L4">
            Node boilerplates
          </a>
        </li>
        <li>
          <a href="https://github.com/polestar/dotnet-boilerplates/blob/main/templates/skeleton/.github/dependabot.yml#L5">
            dotnet boilerplates
          </a>
        </li>
      </ul>
    </>
  );
};
